import { FastAverageColor } from 'fast-average-color';
import useSWRImmutable from 'swr/immutable';

import { type Media, MediaFormatVersion } from '@lp-lib/media';

import { xDomainifyUrl } from '../../utils/common';
import { hexToRgb } from '../../utils/css';
import { MediaUtils } from '../../utils/media';
import { ColorFormatter } from './ColorFormatter';

export function useLogoBrandColor(
  org: Nullable<{ logo: Media | null }>,
  fallbackColorHex = '#3988FF'
) {
  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  return useSWRImmutable(
    ['org-logo-color', logoSrc, fallbackColorHex],
    async () => {
      const defaultColor = new ColorFormatter([...hexToRgb(fallbackColorHex)]);
      if (!logoSrc) return defaultColor;
      const color = await getLogoBrandColor(logoSrc);
      const result = new ColorFormatter(color);
      const isGrayscale = isCloseToGrayscale(result.hsla_0_1);
      return isGrayscale ? defaultColor : result;
    }
  );
}

export async function getLogoBrandColor(logoSrc: string) {
  const fac = new FastAverageColor();
  const result = await fac.getColorAsync(xDomainifyUrl(logoSrc), {
    algorithm: 'dominant',
    ignoredColor: [
      [255, 255, 255, 255, 5],
      [0, 0, 0, 255, 5],
    ],
  });
  return result.value;
}

export function isCloseToGrayscale(hsl: number[]): boolean {
  return hsl[1] < 0.1 || hsl[2] < 0.1 || hsl[2] > 0.9;
}
