import logger from '../../logger/logger';
import { sleep } from '../../utils/common';
import { Emitter } from '../../utils/emitter';
import { releaseMediaStream } from '../../utils/media';
import { unlockAudioContext } from './audio-context';

let counter = 0;
const maxRestartAttempts = 5;
const log = logger.scoped('LocalAEC');

enum PeerName {
  Broadcaster = 'broadcaster',
  Receiver = 'receiver',
}

enum LoopbackBrokenState {
  Unknown = 0b00,
  BroadcasterBroken = 0b01,
  ReceiverBroken = 0b10,
  AllBroken = 0b11,
}

type NamedRTCPeerConnection = {
  name: PeerName;
  instance: RTCPeerConnection;
};

function createNamedRTCPeerConnection(
  name: PeerName,
  configuration?: RTCConfiguration
): NamedRTCPeerConnection {
  const peer = new RTCPeerConnection(configuration);
  return {
    name,
    instance: peer,
  };
}

type Events = {
  'ice-broken-connection-state': (peer: NamedRTCPeerConnection) => void;
};

// See
// https://dev.to/focusedlabs/echo-cancellation-with-web-audio-api-and-chromium-1f8m,
// https://bugs.chromium.org/p/chromium/issues/detail?id=687574,
// https://gist.github.com/alexciarlillo/4b9f75516f93c10d7b39282d10cd17bc for
// how and why this works.

// Also see https://codesandbox.io/p/sandbox/echo-cancellation-forked-4snrp5
// for a test harness demonstrating how these effects behave in various
// browsers.

// Update(04/09/2024): The bug linked above has been fixed. If you test the
// codesandbox example, the echo cancellation works without loopback.

class LocalWebRTCLoopback {
  private peerPair = [
    createNamedRTCPeerConnection(PeerName.Broadcaster),
    createNamedRTCPeerConnection(PeerName.Receiver),
  ] as const;

  constructor(private emitter?: Emitter<Events>) {
    this.peerPair.forEach((p) => {
      p.instance.addEventListener('icecandidate', (e) =>
        this.onIceCandidate(p, e)
      );
      p.instance.addEventListener('iceconnectionstatechange', (e) =>
        this.onIceStateChange(p, e)
      );
    });
  }

  private async onIceCandidate(
    peer: NamedRTCPeerConnection,
    event: RTCPeerConnectionIceEvent
  ) {
    try {
      await this.getOtherPeer(peer.instance).addIceCandidate(
        event.candidate || undefined
      );
      log.info(`peer ${peer.name} addIceCandidate success`);
    } catch (e) {
      log.error(`peer ${peer.name} addIceCandidate failed`, e);
    }
    log.debug(
      `peer ${peer.name} ICE candidate: ${event.candidate?.candidate || 'null'}`
    );
  }

  private onIceStateChange(peer: NamedRTCPeerConnection, event: Event) {
    log.info(
      `peer ${peer.name} ICE state: ${peer.instance.iceConnectionState}`,
      {
        event,
      }
    );
    if (
      peer.instance.iceConnectionState === 'closed' ||
      peer.instance.iceConnectionState === 'disconnected' ||
      peer.instance.iceConnectionState === 'failed'
    ) {
      this.emitter?.emit('ice-broken-connection-state', peer);
    }
  }

  close(): void {
    this.peerPair.forEach((peer) => peer.instance.close());
  }

  async switchSdp(): Promise<void> {
    if (this.peerPair) {
      const getSessionDescription = async (
        peer: RTCPeerConnection,
        cmd: string
      ) => {
        // iceRestart is not working well on Firefox, verified on demo
        // https://webrtc.github.io/samples/src/content/peerconnection/restart-ice/
        const sessionDescription =
          'offer' === cmd
            ? await peer.createOffer(/*{ iceRestart: true }*/)
            : await peer.createAnswer();
        await peer.setLocalDescription(sessionDescription);
        return sessionDescription;
      };
      const setRemoteDescription = async (
        peer: RTCPeerConnection,
        sessionDescription: RTCSessionDescriptionInit | null | undefined
      ) => {
        if (!sessionDescription) {
          throw new Error('sessionDescription can not be empty');
        }
        await peer.setRemoteDescription(sessionDescription);
      };
      const x = await getSessionDescription(this.peerPair[0].instance, 'offer');
      await setRemoteDescription(this.peerPair[1].instance, x);
      const y = await getSessionDescription(
        this.peerPair[1].instance,
        'answer'
      );
      await setRemoteDescription(this.peerPair[0].instance, y);
    }
  }

  private getOtherPeer(peer: RTCPeerConnection): RTCPeerConnection {
    return peer === this.peerPair[0].instance
      ? this.peerPair[1].instance
      : this.peerPair[0].instance;
  }

  get broadcaster(): RTCPeerConnection {
    return this.peerPair[0].instance;
  }

  get receiver(): RTCPeerConnection {
    return this.peerPair[1].instance;
  }
}

export interface IEchoCancellation<Destination extends AudioNode> {
  destination: Destination;
  dispose(): void;
}

export class LocalEchoCancellation
  implements IEchoCancellation<MediaStreamAudioDestinationNode>
{
  private audio: HTMLAudioElement = document.createElement('audio');
  private stream = new MediaStream();
  private id = 0;
  readonly destination: MediaStreamAudioDestinationNode;
  private loopback?: LocalWebRTCLoopback;
  private loopbackBrokenState = LoopbackBrokenState.Unknown;
  private emitter = new Emitter<Events>();
  private restartAttempts = 0;

  constructor(private ctx: AudioContext) {
    this.destination = ctx.createMediaStreamDestination();
    this.watch();
    this.init();
  }

  private init() {
    this.id = counter++;
    log.debug('start echo cancellation unit', { id: this.id });
    this.loopback = new LocalWebRTCLoopback(this.emitter);
    this.loopbackBrokenState = LoopbackBrokenState.Unknown;
    this.loopback.receiver.ontrack = async (a) => {
      if (!this.audio) return;
      this.audio.srcObject = a.streams[0];
      await unlockAudioContext();
      try {
        await new Promise<void>(async (resolve, reject) => {
          let attempts = 100;

          while (attempts > 0) {
            await new Promise<void>((resolve) =>
              setTimeout(() => resolve(), 5000)
            );

            // Wait until the receiver element has the audio track
            if (!this.audio.srcObject) {
              continue;
            }

            attempts--;
            try {
              // attempting to .play() before the user has interacted with the
              // document will throw an exception in chrome, and even if web audio
              // is unlocked on firefox it will still throw without a user gesture.
              await this.audio.play();

              // If a nearly slient source is started first, then Chrome's AEC
              // somehow goes into a low latency mode. It's unclear why this
              // works.
              startConstantNearSilence(this.ctx, this.destination);

              return resolve();
            } catch (err) {
              log.warn(
                'playback start of echo cancellation output was attempted before user gesture received'
              );
            }
          }

          reject();
        });
        log.debug('echo cancellation unit ready', { id: this.id });
      } catch (err) {
        log.error('failed to start local echo cancelation audio output', err);
      }
    };

    this.loopback.broadcaster.addTrack(
      this.destination.stream.getAudioTracks()[0],
      this.stream
    );
    this.loopback.switchSdp();
  }

  private watch(): void {
    this.emitter.on('ice-broken-connection-state', async (peer) => {
      if (peer.name === PeerName.Broadcaster) {
        this.loopbackBrokenState =
          this.loopbackBrokenState | LoopbackBrokenState.BroadcasterBroken;
      }
      if (peer.name === PeerName.Receiver) {
        this.loopbackBrokenState =
          this.loopbackBrokenState | LoopbackBrokenState.ReceiverBroken;
      }
      if (this.loopbackBrokenState === LoopbackBrokenState.AllBroken) {
        if (this.restartAttempts >= maxRestartAttempts) {
          log.info(
            'detect broken loopback, hit max restart attempts, give up.'
          );
          return;
        }
        this.restartAttempts += 1;
        log.info(
          `detect broken loopback, try restart #${this.restartAttempts}`
        );
        let networkChecks = 10;
        while (networkChecks > 0) {
          if (window.navigator.onLine) {
            this.close();
            this.init();
            break;
          }
          await sleep(1000);
          networkChecks--;
        }
      }
    });
  }

  private close(): void {
    log.debug('close echo cancellation unit', { id: this.id });
    if (this.loopback) {
      this.loopback.receiver.ontrack = null;
      this.loopback.close();
    }
    if (this.audio) {
      this.audio.pause();
      this.audio.srcObject = null;
    }
    releaseMediaStream(this.stream);
  }

  dispose(): void {
    this.close();
    this.emitter.clear();
  }
}

function startConstantNearSilence(
  ctx: AudioContext,
  destination: MediaStreamAudioDestinationNode
) {
  const oscillator = ctx.createOscillator();
  oscillator.type = 'square';
  oscillator.frequency.setValueAtTime(1, ctx.currentTime);

  const gain = ctx.createGain();
  gain.gain.value = 0.00001;

  oscillator.connect(gain);
  gain.connect(destination);

  oscillator.start();
}

export class DummyEchoCancellation
  implements IEchoCancellation<AudioDestinationNode>
{
  readonly destination: AudioDestinationNode;

  constructor(ctx: AudioContext) {
    this.destination = ctx.destination;
    this.init();
  }

  private async init() {
    await unlockAudioContext();
  }

  dispose(): void {
    // nothing to do
  }
}
