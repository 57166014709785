import { createContext, useContext } from 'react';

export function createProvider<API>(name: string) {
  const context = createContext<API | null>(null);

  function useCreatedContext() {
    const ctx = useContext(context);
    if (!ctx) {
      throw new Error(`No provider: ${name}`);
    }
    return ctx;
  }

  function useOptionalContext() {
    const ctx = useContext(context);
    return ctx;
  }

  return {
    Provider: context.Provider,
    useCreatedContext,
    useOptionalContext,
  } as const;
}
