import * as Tone from 'tone';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import {
  DummyEchoCancellation,
  type IEchoCancellation,
  LocalEchoCancellation,
} from './aec';

type AudioContextDesc = {
  aec: IEchoCancellation<AudioNode>;
  ctx: AudioContext;
};

let PRIMARY: AudioContextDesc | null = null;
const useEchoCancellation = getFeatureQueryParam('use-hack-echo-cancellation');

const getAudioContextDesc = () => {
  if (!PRIMARY) {
    const ctx = Tone.getContext().rawContext as AudioContext;
    PRIMARY = {
      ctx: ctx,
      aec: useEchoCancellation
        ? new LocalEchoCancellation(ctx)
        : new DummyEchoCancellation(ctx),
    };
  }
  return PRIMARY;
};

export const getAudioContext = (): AudioContext => {
  return getAudioContextDesc().ctx;
};

export const getEchoCancelledAudioDestination = (): AudioNode => {
  const desc = getAudioContextDesc();
  return desc.aec ? desc.aec.destination : desc.ctx.destination;
};

export const closeAudioContext = async (): Promise<void> => {
  if (PRIMARY) {
    const p = PRIMARY;
    PRIMARY = null;
    p.aec?.dispose();
    return p.ctx.close();
  }
};

export const unlockAudioContext = async (): Promise<boolean> => {
  await Tone.start();
  const ctx = getAudioContext();
  return ctx.state !== 'suspended';
};

export const createSilentAudioElement = () => {
  // Safari will prevent any initial calls to audio.play() unless there has
  // previously been a user-initiated gesture to do so. The echo canceler uses
  // an audio tag to emit echo canceled audio.

  // The smallest wave file from https://github.com/mathiasbynens/small
  const uri =
    'data:audio/wav;base64,UklGRjoAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YRYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA';
  const audio = new Audio();
  audio.src = uri;
  return audio;
};
